import React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import { TextContainer } from '../utils'

const browser = typeof window !== 'undefined' && window

const NotFoundPage = () =>
    browser && (
        <Layout>
            <TextContainer>
                <h1>404: Pagina niet gevonden</h1>
                <p>Deze pagina bestaat niet (meer). Onze excuses voor het ongemak.</p>
            </TextContainer>
        </Layout>
    )

export default withPrismicUnpublishedPreview(NotFoundPage)
